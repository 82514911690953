<template>
  <v-app id="inspire">
    <v-app-bar class="font-weight-bold" app>
      <router-link to="/">
        <v-toolbar-title class="pb-1 pr-3">
          <v-img
            width="140"
            contain
            height="40"
            src="@/assets/goclean-logo.svg"
          />
        </v-toolbar-title>
      </router-link>
      <v-spacer />
      <a href="tel:+64-027-440-6794"
        ><v-icon
          color="#00aeef"
          size="30"
          class="mr-3 mt-1 d-none d-sm-block"
          >{{ svgPath }}</v-icon
        ></a
      >
      <v-toolbar-title class="pr-5 d-none d-sm-block">
        <a href="tel:+64-027-440-6794"> <h3>027-440-6794</h3> </a>
      </v-toolbar-title>
      <dialog1 title="Free Quote" />
    </v-app-bar>
    <v-main>
      <v-container class="pa-0 ma-0" fluid
        ><router-view> </router-view
      ></v-container>
    </v-main>
    <v-footer color="#00aeef">
      <span class="white--text">Goclean &copy; 2022</span>
    </v-footer>
  </v-app>
</template>
<script>
import dialog1 from "@/components/dialog1.vue";
import { mdiPhone } from "@mdi/js";
export default {
  name: "app",
  components: {
    dialog1,
  },
  data: () => ({
    svgPath: mdiPhone,
  }),
};
</script>
<style lang="scss">

a:link {
  text-decoration: none;
  :hover {
    color: #2e3192;
  }
}
h2,
h3 {
  color: #00aeff;
}
</style>
